// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const REACT_APP_API_URL = 'https://assyp-backend.herokuapp.com/api/v1/';
// export const REACT_APP_API_URL = 'http://localhost:5000/api/v1/';

export const SUCCESS_RESPONSE = 'success';
export const EMAIL_EXISTS = 'Email already exists';
// export const REACT_APP_FRONTEND_URL = 'http://localhost:3000/';
export const REACT_APP_FRONTEND_URL = 'https://assyp-frontend.netlify.app/';
// export const REACT_APP_FRONTEND_URL = 'https://grupoassyp.mx/';

// Roles
export const RolesType = {
    SUPER_USER_ID: 'SUPER_USER_ID',
    ADMIN_ROLE_ID: 'ADMIN_ROLE_ID',
    USER_ROLE_ID: 'USER_ROLE_ID',
    ACCOUNTANT_ROLE_ID: 'ACCOUNTANT_ROLE_ID'
};

export const rolesIds = {
    [RolesType.ADMIN_ROLE_ID]: '6400d35f3185c8f1f8e39b62',
    [RolesType.USER_ROLE_ID]: '6444762ef2872ea52266dc2e',
    [RolesType.SUPER_USER_ID]: '6444c9633bbf9f0e9943dbac',
    [RolesType.ACCOUNTANT_ROLE_ID]: '646aedf9a58e51776f800412'
};
