// material-ui
import { Typography } from '@mui/material';

// project imports
import menuItem from 'menu-items';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { rolesIds } from 'store/constant';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const navigate = useNavigate();

    const [user, setUser] = useState();

    const role = user?.roles?.[0];
    const filteredItems = menuItem.items.filter((item) => {
        return rolesIds[item.role] === role;
    });

    useEffect(() => {
        const user = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : '';
        if (!user || user?.length === 0) {
            navigate('/inicio-sesion');
            return;
        } else {
            setUser(user);
        }
    }, []);
    if (filteredItems.length === 0) {
        return;
    }

    const filteredNavigation = { items: filteredItems };
    const navItems = filteredNavigation.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
