import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

const Historial = Loadable(lazy(() => import('views/historial')));
const Clientes = Loadable(lazy(() => import('views/clientes')));

// Vies Routing
const RegistroAdmin = Loadable(lazy(() => import('views/registro_admin')));
const ListaUsuarios = Loadable(lazy(() => import('views/lista-usuarios')));

const Captura = Loadable(lazy(() => import('views/captura')));
const Revision = Loadable(lazy(() => import('views/revision')));

//Views Counter User
const AgregarCliente = Loadable(lazy(() => import('views/agregar-cliente')));
const ListaClientes = Loadable(lazy(() => import('views/lista-clientes')));
const ReportesContador = Loadable(lazy(() => import('views/reportes-contador')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'historial',
            element: <Historial />
        },
        {
            path: 'clientes',
            element: <Clientes />
        },
        {
            path: 'captura',
            element: <Captura />
        },
        {
            path: 'captura/:id',
            element: <Captura />
        },
        {
            path: 'revision',
            element: <Revision />
        },
        {
            path: 'registro_admin',
            element: <RegistroAdmin />
        },
        {
            path: 'lista_usuarios',
            element: <ListaUsuarios />
        },
        {
            path: 'agregar-cliente',
            element: <AgregarCliente />
        },
        {
            path: 'agregar-cliente/:id',
            element: <AgregarCliente />
        },
        {
            path: 'lista-clientes',
            element: <ListaClientes />
        },
        {
            path: 'reportes-contador',
            element: <ReportesContador />
        }
    ]
};

export default MainRoutes;
