// assets
import { IconCalculator, IconDashboard } from '@tabler/icons';
import { RolesType } from 'store/constant';

// constant
const icons = { IconCalculator, IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const counter = {
    role: RolesType.ACCOUNTANT_ROLE_ID,
    id: 'contador',
    title: 'Contador',
    type: 'group',
    children: [
        {
            id: 'AgregarCliente',
            title: 'Agregar Cliente',
            type: 'item',
            url: '/agregar-cliente',
            icon: icons.IconCalculator,
            breadcrumbs: false
        },
        {
            id: 'ListaClientes',
            title: 'Lista de Clientes',
            type: 'item',
            url: '/lista-clientes',
            icon: icons.IconCalculator,
            breadcrumbs: false
        },
        {
            id: 'ReportesContador',
            title: 'Reportes',
            type: 'item',
            url: '/reportes-contador',
            icon: icons.IconCalculator,
            breadcrumbs: false
        }
    ]
};

export default counter;
