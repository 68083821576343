// assets
import { IconCalculator, IconDashboard } from '@tabler/icons';
import { RolesType } from 'store/constant';

// constant
const icons = { IconCalculator, IconDashboard };

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const admin = {
    role: RolesType.ADMIN_ROLE_ID,
    id: 'admin',
    title: 'Administrador',
    type: 'group',
    children: [
        {
            id: 'Registrar',
            title: 'Registrar',
            type: 'item',
            url: '/captura',
            icon: icons.IconCalculator,
            breadcrumbs: false
        },
        {
            id: 'Clientes',
            title: 'Clientes',
            type: 'item',
            url: '/clientes',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
        // {
        //     id: 'Importar',
        //     title: 'Importar',
        //     type: 'item',
        //     url: '/importar',
        //     icon: icons.IconDashboard,
        //     breadcrumbs: false
        // }
    ]
};

export default admin;
