import user from './user';
import superadmin from './superadmin';
import admin from './admin';
import counter from './counters';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [user, superadmin, admin, counter]
};

export default menuItems;
