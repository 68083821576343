// assets
import { IconCalculator, IconDashboard } from '@tabler/icons';
import { RolesType } from 'store/constant';

// constant
const icons = { IconCalculator, IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const user = {
    role: RolesType.USER_ROLE_ID,
    id: 'usuario',
    title: 'Usuario',
    type: 'group',
    children: [
        {
            id: 'Revision',
            title: 'Revision',
            type: 'item',
            url: '/revision',
            icon: icons.IconCalculator,
            breadcrumbs: false
        },
        {
            id: 'Historial',
            title: 'Historial',
            type: 'item',
            url: '/historial',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default user;
