// assets
import { IconKey, IconClipboardList } from '@tabler/icons';
import { RolesType } from 'store/constant';

// constant
const icons = {
    IconKey,
    IconClipboardList
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const superadmin = {
    role: RolesType.SUPER_USER_ID,
    id: 'superadmin',
    title: 'Super Admin',
    type: 'group',
    children: [
        {
            id: 'registrar_admin',
            title: 'Registrar Usuario',
            type: 'item',
            url: '/registro_admin',
            icon: icons.IconKey,
            breadcrumbs: false
        },
        {
            id: 'list_users',
            title: 'Lista de Usuarios',
            type: 'item',
            url: '/lista_usuarios',
            icon: icons.IconClipboardList,
            breadcrumbs: false
        }
    ]
};

export default superadmin;
